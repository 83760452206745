<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
          <span class="text-danger" v-show='archive'>&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchDevice"
            v-model="searchDevice"
            placeholder="Rechercher un appareil"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="newDevice('')"
            class="d-flex px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span> 
          </b-button>
          <b-dropdown class="bg-outline-primary btn-group-icon" variant="outline-primary">
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item class="w-full border-bottom-grey-light" @click='archive=!archive'>{{ !archive ? 'Voir les appareils archiv&eacute;s' : 'Voir les appareils actifs' }}</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingDevicesList">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{'Chargement en cours de la liste des appareils...'}}
        </div> 
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :gridOptions="gridOptions"
          :key="filter"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>
    <sidebar-device ref="sidebarDevice"/>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'


import SidebarDevice from '@/components/device/SidebarDevice'


export default {
  components: {
    BButton,
    AgGridVue,
    SidebarDevice
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive: false,
      filter: false,
      sidebar:false,
      activeSidebarDevice:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchDevice: ''
    }
  },
  computed: {
    ...mapGetters([
      'devicesList',
      'devicesListArchived',
      'customersList',
      'poolsList',
      'isLoadingDevicesList',
      'isOpenSidebarDevice',
    ]),
    gridData () {
      if (this.archive) {
        return this.devicesListArchived
      }else{
        return this.devicesList
      }
      
    }
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      { field: 'label', headerName: 'Nom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'deviceType.label', headerName: 'Type', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'date', headerName: 'Date', resizable:true, lockVisible:true, sortable: true, minWidth: 160, cellRenderer: params => {return this.dateConvert(params.value)}},
      { field: 'brand.label', headerName: 'Marque', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
    ];
  },
  created(){
    this.fetchDevicesList();
    this.fetchDevicesListArchived();
    this.fetchCustomersList();
    this.fetchPoolsList();
  },
  methods: {
    ...mapActions([ 
      'fetchDevicesList',
      'fetchDevicesListArchived',
      'deleteDevice',
      'fetchPoolsList',
      'fetchCustomersList'
    ]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
      this.$nextTick(() => {
        //this.autoSizeAll(true)
      })
    },
    onRowClicked(event){
      if (this.archive) {
        this.$refs['sidebarDevice'].getDeviceArchivedDetails(event.data.id, true)
      }else {
        this.$refs['sidebarDevice'].getDeviceDetails(event.data.id)
      }
      this.$store.commit('SET_IS_OPEN_SIDEBAR_DEVICE', true)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchDevice
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    dateConvert(datePull) {
      const date = new Date(datePull);
      const jour = date.getDate();
      const mois = date.getMonth() + 1;
      const annee = date.getFullYear();
      const heures = date.getHours();
      const minutes = date.getMinutes();
      const dateFormatee = `${jour < 10 ? '0' + jour : jour}/${mois < 10 ? '0' + mois : mois}/${annee} ${heures < 10 ? '0' + heures : heures}:${minutes < 10 ? '0' + minutes : minutes}`;
      return dateFormatee;
    },
    customer(id){
      const customer = this.customersList.find(elem => elem.id == id)
      if (customer) {
        return customer.firstName+' '+customer.lastName
      } else {
        return ''
      } 
    },
    pool(id){
      const pool = this.poolsList.find(elem =>elem.id == id)
      if (pool) {
        return pool.label
      }
      else return ''
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },
    newDevice () { 
      this.$router.push({ path: '/new-device' })
    },
  }
}
</script>
